<template>
   <div class="lower-slide-section left-lower-slide-section" v-click-outside="onOutsideClick">
        <input class="search-input-container" 
            type="search" 
            id="gsearch" 
            placeholder="Search"
            name="gsearch"
            v-model="query"
            @keyup.enter.prevent="gotoResultPage"
            :class="showSearchInput && 'search-input'"
            autofocus
        >
        <i class="fa fa-search tag search-img" v-if="showSearchInput" @click="gotoResultPage"></i>
        <i class="fa fa-search tag search-img" v-if="!showSearchInput" @click="gotoResultPage"></i>                
    </div>
</template>

<script setup>
const router = useRouter();

const emit = defineEmits(['hide-nav']);

const query = ref("");
const showSearchInput = ref(true);

const gotoResultPage = () => {
    if (query.value && query.value.length > 0){
        let qv = ''
        qv = query.value
        router.push({
            name: "shop",
            query: {
                query: qv,
                categoryName: 'all'
            },
        });
        emit('hide-nav')
    } else {
        showSearchInput.value = !showSearchInput.value
    }
    
    query.value = "";
    
};

const onOutsideClick = () => {
    showSearchInput.value = true
};
</script>

<style lang="scss">
.search-input {
    visibility: hidden;
}
.lower-slide-section {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
//   column-gap: 5px !important;
}
.left-lower-slide-section {
  width: 103%;
  margin-right: -13px;
}
.search-input-container {
    border: 1px solid #f6f6f6 !important;
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    transition: all .3s ease-out 0s !important;
    padding: 12px !important;
    &::placeholder {
        font-size: 16px;
    }

    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 12px 0;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    margin-bottom: 0px;
    border: 0;
    // border-radius: 8px;
    font-size: 15px;
    background-color: #F6F7F8;
    text-indent: 0;
    color: #343538;
    outline: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    // height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: 0 1px 0px 0 #0000000D inset;
    line-height: 22px;
    font-weight: 500;
    border-radius: 3px;
}
.search-img {
    right: 30px !important;
    font-size: 20px !important;
}
.search-close-img {
    right: 35px !important;
    font-size: 20px !important;
}
</style>

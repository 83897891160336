// import HttpClient from "../http-commons/HttpClient.js";
import BaseClient from "./BaseClient";

class CurrenciesClient extends BaseClient {
    constructor(host, apiKey, endpoint) {
        super();
        this._host = host;
        this._apiKey = apiKey;
        this._endpoint = endpoint;
    }

    async fetchCurrencies(params) {
        let url = this.getUrl(this._host + this._endpoint, params);
        let headers = {
            'Content-Type': 'application/json',
        };

        headers['X-API-KEY'] = this._apiKey;
        headers['X-DEVICE-ID'] = 'aa=';
        if (process.client && localStorage.getItem("token")) {
      headers["X-AUTH-TOKEN"] = localStorage.getItem("token");
    }

        return this.makeRequest(url, 'GET', {}, headers);
    }

}

export default CurrenciesClient;

<template>
    <div class="navbar-container-tab"  id="tabNavLink">

        <div class="navbar-top-section">
            <div class="" style="display:flex; width: 100%;">
            <i
                class="fa fa-bars col-2 d-flex justify-content-center align-items-center"
                style="font-size: 24px; cursor: pointer; width: 10%;"
                @click.prevent="showTabNav()"
            ></i>
            <div class="logo-section col-8" style="width: 90%;display:flex;justify-content:center">
                <RouterLink to="/" @click.prevent="gotoIndexPage()">
                    <img
                        class="logo-img" 
                        src="/logo/delta.png" 
                        alt="Logo" 
                    />
                </RouterLink>
            </div>
        </div>

        </div>
    
        <div class="tab-nav-sidebar" v-show="tabNavVisibility" id="navLink">
            <div style="padding: 0 25px;">
                <CartSectionMobile @hideNav="showTabNav"/>
            </div>
            <NavbarItems style="margin-top: 15px;" @hideNav="showTabNav" type="tab"/>
        </div>
    
    </div>
</template>

<script setup>
import CartSectionMobile from "./CartSectionMobile.vue";
import NavbarItems from "./NavbarItems.vue";

const router = useRouter();

const gotoIndexPage = async () => {
  router.push({
      name: "index",
  })
  showTabNav();
}

const tabNavVisibility = ref(false);

const showTabNav = async () => {
  tabNavVisibility.value = !tabNavVisibility.value;
};

</script>

<style lang="scss">
@media screen and (min-width: 1275px) {
    .navbar-container-tab {
      display: none;
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 650px) {
    .navbar-container-tab {
      display: none;
    }
  }
</style>

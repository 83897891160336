<template>
    <div class="navbar-container">

        <div class="navbar-top-section">

            <div class="row">

                <div class="col-12 col-lg-12 col-md-12" style="display: flex;">
                    <div class="logo-section">
                        <RouterLink to="/" @click.prevent="gotoIndexPage()">
                            <img class="logo-img" src="/logo/delta.png" alt="Logo" />
                        </RouterLink>
                    </div>

                    <SearchBar @hideNav="$emit('hideNav')" />

                    <CurrencyDropdown />

                    <CartSection />
                </div>

            </div>
        </div>

        <div class="navbar-middle-section-v2" id="navLink">
            <NavbarItems @gotoIndexPage="gotoIndexPage()" type="desktop"/>
        </div>

        
        <CommonsModalBaseModal 
            title="" 
            :show="mailUsModalVisibility" 
            @close="mailUsModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
        <MailUs @close="mailUsModalVisibility = false" />
        </CommonsModalBaseModal>

    </div>
</template>

<script setup>
import CartSection from "./CartSection.vue";
import NavbarItems from "./NavbarItems.vue";
import MailUs from "../MailUs.vue";
import SearchBar from "./SearchBar.vue";
import CurrencyDropdown from "./CurrencyDropdown.vue";

const router = useRouter();
const mailUsModalVisibility = ref(false);

const gotoIndexPage = async () => {
  router.push({
      name: "index",
  })
}
</script>

<style lang="scss">
@media screen and (min-width: 0px) and (max-width: 1200px) {
    .navbar-container {
        display: none;
    }

    .top-nav-bar-desktop {
        display: none;
    }

}

@media screen and (min-width: 1201px) {
    .navbar-container-tab {
        display: none;
    }

}






// @media screen and (min-width: 0px) and (max-width: 1200px) {
//     .navbar-container {
//       display: none;
//     }
//   }


// .shake {
//     animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
//     transform: translate3d(0, 0, 0);
// }

// @keyframes shake {
//     10%,
//     90% {
//     transform: translate3d(-1px, 0, 0);
//     }

//     20%,
//     80% {
//     transform: translate3d(2px, 0, 0);
//     }

//     30%,
//     50%,
//     70% {
//     transform: translate3d(-4px, 0, 0);
//     }

//     40%,
//     60% {
//     transform: translate3d(4px, 0, 0);
//     }
// }
</style>

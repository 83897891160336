<template>
    <div class="right-section">
        <div class="right-upper-side">
            <div
                class="upper-side-section"
                @click.prevent="goToOutletPage()"
                @hideNav="showMobileNav"
            >
                <img
                    class="location-logo"
                    src="../../../../assets/images/one/locaiton2.png"
                    alt="location"
                />
                <p>Showrooms</p>
            </div>

            <div class="upper-side-section" @click.prevent="showAccount">
                <img
                    class="account-logo"
                    src="../../../../assets/images/one/account.jpeg"
                    alt="account"
                />
                <p>
                    {{ myAccount.charAt(0).toUpperCase() + myAccount.slice(1) }}
                </p>
            </div>

            <ul
                class="account-list drop-down-menu"
                v-if="accountList"
                v-click-outside="onOutsideClick"
                :class="[accountList == true && 'list-click']"
            >
                <li
                    class="option"
                    style="font-size: 20px"
                    v-for="(item, index) in accountArray"
                    :key="index"
                    @click.prevent="onAccountClick(item)"
                    @hideNav="showMobileNav"
                >
                    <a
                        class="lower-slide-section"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>
                            <i class="fa" :class="item.img"></i>
                            <span>{{ item.title }}</span>
                        </div>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>

            <div
                class="upper-side-section cart-section desktop"
                @click.prevent="showCart()"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-cart"
                    >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path
                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                        ></path>
                    </svg>
                </div>
                <span v-if="cartStore.quantity > 0"
                    >{{ cartStore.quantity }}
                </span>
            </div>
            <div
                class="upper-side-section cart-section desktop"
                @click.prevent="showFavorite()"
            >
                <i class="fa fa-heart-o" style="font-size: 22px"></i>
            </div>

            <div
                class="upper-side-section cart-section mobile"
                @click.prevent="gotoCartDetailPage()"
                @hideNav="showMobileNav"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-cart"
                    >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path
                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                        ></path>
                    </svg>
                </div>
                <span class="cart-span" v-if="cartStore.quantity > 0"
                    >{{ cartStore.quantity }}
                </span>
            </div>
            <div
                class="upper-side-section cart-section mobile"
                @click.prevent="showFavorite()"
            >
                <i class="fa fa-heart-o" style="font-size: 22px"></i>
            </div>
        </div>

        <CartDropdown
            v-if="cartVisibility"
            :class="{ 'cart-dropdown-active': isActive }"
            class="cart-dropdown"
            @onDropdownModalVisibility="cartVisibility = false"
            :cartStore="cartStore"
            @hideNav="$emit('hideNav')"
        />

        <div class="right-lower-side">
            <div class="flag-section" style="position: relative">
                <div class="lower-slide-section right-lower-slide-section">
                    <img class="bd-flag" :src="currentFlag.img" alt="flag" />
                    <span>{{ currentFlag.currency }}</span>
                    <img
                        class="drop-down"
                        @click.prevent="showList()"
                        src="../../../../assets/images/one/dropdown.png"
                        alt="flag"
                    />
                </div>
                <ul
                    class="flag-list drop-down-menu"
                    v-if="currencyList"
                    v-click-outside="onOutsideClick"
                    :class="[currencyList == true && 'list-click']"
                >
                    <li
                        class="option"
                        v-for="(item, index) in flagsArray"
                        :key="index"
                        @click.prevent="selectFlag(item)"
                    >
                        <div class="lower-slide-section">
                            <img class="bd-flag" :src="item.img" alt="flag" />
                            <span>{{ item.currency }}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <SearchBar @hideNav="$emit('hideNav')" />
        </div>
        <CommonsModalBaseModal 
            title="" 
            :show="signInModalVisibility" 
            @close="signInModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
        <SignIn
                v-if="modalTab == 'signIn'"
                @close="signInModalVisibility = false"
                @openSignUp="openSignUp"
            />
        </CommonsModalBaseModal>
        <CommonsModalBaseModal 
            title="" 
            :show="signUpModalVisibility" 
            @close="signUpModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
            <SignUp
                @openSignIn="openSignIn"
                @close="signUpModalVisibility = false"
            />
        </CommonsModalBaseModal>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import SearchBar from "../../../../components/commons/nav/component/SearchBar.vue";
import CartDropdown from "../../cart/CartDropdown.vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth-store";
import SignIn from "@/components/commons/SignIn.vue";
import SignUp from "@/components/commons/SignUp.vue";

const router = useRouter();
const cartStore = useCartStore();
const authStore = useAuthStore();

const cartVisibility = ref(false);
const currencyList = ref(false);
const accountList = ref(false);
const signInModalVisibility = ref(false);
const signUpModalVisibility = ref(false);
const isActive = ref(false);
const emit = defineEmits(["hide-nav"]);
const modalTab = "signIn";

const flagsArray = [
    {
        img: "/images/one/flags/bdflag.png",
        currency: "BDT",
    },
    {
        img: "/images/one/flags/usflag.png",
        currency: "USD",
    },
    {
        img: "/images/one/flags/indiaflag.png",
        currency: "INR",
    },
];

const accountArray = [
    {
        title: "Profile",
        img: "fa-user",
        path: "profile",
    },
    {
        title: "Sign Out",
        img: "fa-power-off",
    },
];

const currentFlag = ref(flagsArray[0]);

const showCart = async () => {
    cartVisibility.value = !cartVisibility.value;
    isActive.value = true;

    emit("hideNav");
};
const openSignUp = () => {
    signUpModalVisibility.value = true;
    signInModalVisibility.value = false;
};
const openSignIn = () => {
    signUpModalVisibility.value = false;
    signInModalVisibility.value = true;
};
const gotoCartDetailPage = () => {
    router.push({
        name: "cart",
    });
    emit("hideNav");
};

const goToOutletPage = () => {
    router.push({
        name: "outlets",
    });

    emit("hideNav");
};

const onAccountClick = (item) => {
    if (item.path && item.path == "profile") {
        router.push({
            name: "profile",
        });
    } else {
        onSignOut();
    }

    accountList.value = false;
    emit("hideNav");
};

const showList = () => {
    currencyList.value = !currencyList.value;
};
const showAccount = () => {
    if (authStore.currentUser != null) {
        accountList.value = !accountList.value;
    } else {
        signInModalVisibility.value = true;
    }
};

const selectFlag = (item) => {
    currentFlag.value = item;
    currencyList.value = !currencyList.value;
};

const onSignOut = async () => {
    await authStore.signOut();
    router.push({
        name: "index",
    });
};

const myAccount = computed(() => {
    let title = "";
    if (authStore.currentUser != null) {
        title = authStore.profile.name.toLowerCase();
    } else {
        title = "My Account";
    }
    return title;
});

const onOutsideClick = () => {
    currencyList.value = false;
    accountList.value = false;
};

const mobileNavVisibility = ref(false);

const showMobileNav = async () => {
    mobileNavVisibility.value = !mobileNavVisibility.value;
};
</script>

<style lang="scss">
.flag-list {
    display: none;
    max-height: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    opacity: 0;
    overflow: inherit;
    padding: 0;
    position: absolute;
    top: 45px;
    left: -15px;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
}
.flag-list:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.flag-list:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2b1a41;
    z-index: 0;
}

.list-click {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
    max-height: 300px;
    display: block !important;
}
.account-list {
    display: none;
    max-height: 300px;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    overflow-y: inherit;
    padding: 15px;
    position: absolute;
    top: 60px;
    right: 0%;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
    border: 1px solid #cacaca;
}
.drop-down-menu:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.drop-down-menu:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 11px !important;
    border-bottom-color: #2b1a41;
    z-index: 0;
}
.account-list .option:hover {
    color: #ea1a21;
}
.account-list .option .lower-slide-section span {
    margin-left: 10px;
}
.option {
    opacity: 1 !important;
    display: list-item !important;
    cursor: pointer;
    font-weight: 400;
    line-height: 40px !important;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 10px;
    padding-right: 40px;
    padding: 10px 20px;
    text-align: left;
    transition: all 0.2s;
    margin-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    div {
        .bd-flag {
            margin-right: 5px;
        }
    }
}
.option:last-child {
    border-bottom: none;
}
</style>

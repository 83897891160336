<template>
    <SiteNavbar />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
    <SiteFooter />
    <client-only>
        <nuxt-facebook-chat :options="options" />
    </client-only>
    <CommonsModalBaseModal
        title=""
        :show="showModal"
        @close="showModal = false"
        maxWidth="60%"
        :bgImgStyle="{ backgroundImage: `url(${campaignImg})`, height: '60vh !important' }"
        :modalFooter="false"
        body-style="padding: 0"
    >
        <div class="w-100 h-100 position-relative overflow-auto">
            <div
                class="w-100 h-100 text-white position-absolute d-flex justify-content-center align-items-center flex-column"
                style="z-index: 99"
            >
                <!-- <p class="display-4">
                    {{ campaignTitle }}
                </p> -->
            </div>
        </div>
    </CommonsModalBaseModal>
    <CommonsModalCouponModal
        title=""
        :show="show2Modal"
        @close="show2Modal = false"
        maxWidth="60%"
        :modalFooter="false"
        body-style="padding: 0"
    >
        <div class="row">
            <div
                class="col-6"
                :style="{
                    backgroundImage: `url(${subscriptionOfferImg})`,
                    height: '70vh !important',
                    backgroundSize: 'cover',
                }"
            ></div>
            <div class="col-6" style="margin: auto">
                <div class="px-3">
                    <h1 class="fs-1 fw-bolder text-center">Limited Time</h1>
                    <h1 class="fs-1 fw-bolder text-center">{{subscriptionOfferTitle}}</h1>
                    <!-- <h1 class="fs-1 fw-bolder text-center">15% off</h1> -->
                    <p class="fs-5 text-center">
                        Save your first order and get email only offer when you
                        join
                    </p>
                    <div
                        class="d-flex w-100 my-0 py-2 border border-black position-relative mt-2"
                    >
                        <div class="w-100">
                            <input
                                type="text"
                                v-model="subscriptionEmail"
                                class="form-control bg-white border-0 w-100"
                                id="gsearch"
                                name="gsearch"
                                placeholder="Enter your mail"
                                aria-label="Enter your mail"
                                aria-describedby="basic-addon2"
                            />
                        </div>
                        <br />
                    </div>
                    <small class="text-danger m-0 d-flex align-items-center">
                        {{ errorMessage.email }}
                    </small>
                    <FormBaseButton
                        caption="Continue"
                        icon=""
                        @click.prevent="emailSubscription"
                        class="primary-btn w-100 rounded-0 fw-bolder shadow-none py-3 bg-dark mt-2"
                        color="btn-secondary"
                        :disabled="false"
                        :loading="isLoading"
                    />
                </div>
            </div>
        </div>
    </CommonsModalCouponModal>
</template>

<script setup>
import SiteNavbar from "./components/commons/nav/SiteNavbar.vue";
import SiteFooter from "./components/commons/footer/SiteFooter.vue";
import { useCartStore } from "@/stores/cart.js";
import { useAuthStore } from "./stores/auth-store";
import { useCategoryStore } from "@/stores/category-store";
import { useCurrenciesStore } from "@/stores/currencies-store";
import { useAttributeStore } from "@/stores/attribute-store";
import { useWishlistStore } from "@/stores/wishlist-store";
import { useGlobalDiscountStore } from "@/stores/global-discount-store";
import { kvpsClient, authClient } from "@/assets/apis/config.js";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

// Create an instance of Notyf
const notyf = process.client? new Notyf(): null;
// Define your chat options
const options = {
    pageId: "125303071504889", // Your Facebook Page ID
    locale: "en_US", // Set the locale for the chat
    themeColor: "#ea1a21;", // Customize the chat theme color
};

useHead({
    title: "Delta Furnisher",
    // titleTemplate: (titleChunk) => {
    //     return titleChunk ? `${titleChunk} - Site Title` : 'Site Title';
    // },
    // titleTemplate: "%s - Company",
    bodyAttrs: {
        class: "",
    },
    script: [{ innerHTML: "" }],
});

useSeoMeta({
    //   title: 'Delta Furnisher',
    ogTitle: "Delta Furnisher",
    description: "Delta Furnisher",
    ogDescription: "Delta Furnisher.",
    ogImage: "/logo/logo.png",
});
useServerSeoMeta({
    //   title: 'Delta Furnisher',
    ogTitle: "Delta Furnisher",
    description: "Delta Furnisher",
    ogDescription: "Delta Furnisher.",
    ogImage: "/logo/logo.png",
});

const route = useRoute();
const cartStore = useCartStore();
const authStore = useAuthStore();
const categoryStore = useCategoryStore();
const currenciesStore = useCurrenciesStore();
const attributeStore = useAttributeStore();
const wishlistStore = useWishlistStore();
const globalDiscountStore = useGlobalDiscountStore();

const showModal = ref(false);
const show2Modal = ref(false);
const hasCampaign = ref(false);
const hasSubscriptionOffer = ref(false);
const subscriptionOfferTitle = ref("");
const isLoading = ref(false);
const campaignTitle = ref("");
const modalWidth = ref(50);
const imageRatio = ref(1);
const campaignImg = ref("");
const subscriptionOfferImg = ref("/images/coupon.png");
// const couponImage = ref("/images/coupon.png");
const currentScreen = ref(process.client ? window.screen.width : 0);
const isHome = ref(route.path != "/" ? false : true);
const subscriptionEmail = ref("");
const errorMessage = ref({
    email: "",
});
const onSuccess = async () => {
    await clearValidations();
    subscriptionEmail.value = "";
};

const clearValidations = async () => {
    Object.keys(errorMessage.value).forEach((key) => {
        errorMessage.value[key] = "";
    });
};

const validate = async () => {
    clearValidations();
    if (subscriptionEmail.value.trim().length == 0) {
        errorMessage.value.email = "Please Provide Your Email";
        return false;
    }

    if (!subscriptionEmail.value.match(/\S+@\S+\.\S+/)) {
        errorMessage.value.email = "Please Provide a Correct Email";
        return false;
    }
    return true;
};
const emailSubscription = async () => {
    if (await validate()) {
        let data = {
            userId: "",
            email: subscriptionEmail.value,
            extras: {
                offer: subscriptionOfferTitle.value,
                type: "first-time"
            }
        };
        authClient.emailSubscription(data).then((res) => {
            if (res.status == 201 && !res.networkError) {
                notyf.success('Subscription Successful');
                onSuccess();
            } else if (res.status == 409 && !res.networkError) {
                notyf.error('Already Subscribed with this email');
                // toaster.error(`Failed to Subscribe`, {
                //     duration: 1500,
                // });
            } else {
                notyf.error('Failed to Subscribe');
                // toaster.error(`Failed to Subscribe`, {
                //     duration: 1500,
                // });
            }
        });
    }
    // else {
    //     toaster.error(errorMessage.value.email, {
    //         duration: 1500,
    //     });
    // }
};
if (process.client) {
    const img = new Image();
    img.onload = function () {
        let ration = this.width / this.height;
        imageRatio.value = ration.toFixed(3);
    };
    img.src = "/images/initial-popup.jpg";
}
const modalHeight = ref(modalWidth.value / imageRatio.value);
const isAuthenticated = computed(() => {
    let ac = false;
    if (authStore.currentUser != null) {
        ac = true;
    }
    return ac;
});
watch(
    [hasCampaign, campaignImg, isHome],
    () => {
        if (isHome.value) {
            const shouldShowModal =
                hasCampaign.value && campaignImg.value?.length > 0;
            showModal.value = shouldShowModal;
        }else showModal.value = false;
    },
    { deep: true, immediate: true }
);

watch(
    [hasSubscriptionOffer, subscriptionOfferImg, hasCampaign, isAuthenticated, isHome],
    () => {
        if (!hasCampaign.value && !isAuthenticated.value && isHome.value) {
            const shouldShowModal = hasSubscriptionOffer.value && subscriptionOfferImg.value?.length > 0;
            show2Modal.value = shouldShowModal;
        } else show2Modal.value = false;
    },
    { deep: true, immediate: true }
);

watch(
    currentScreen,
    () => {
        modalWidth.value = currentScreen.value < 991 ? 80 : 50;
        modalHeight.value = modalWidth.value / imageRatio.value;
    },
    { deep: true, immediate: true }
);

const fetchCampaign = async () => {
    let params = {
        textParams: [
            {
                field: "type",
                value: "campaign",
                matchType: "EXACT",
            },
        ],
        nestedParams: [],
        sortParams: [
            {
                field: "title",
                type: "ASCENDING",
            },
        ],
        page: 1,
        size: 100,
    };

    let res = await kvpsClient.search(params);
    
    if (res.status == 200 && !res.networkError && res?.body?.list) {
        let campaign = res?.body?.list.filter((i) => i.type == "campaign");
        if (campaign.length) {
            campaignImg.value = campaign[0]?.fields?.image;
            hasCampaign.value =
                !campaign[0]?.fields?.hasCampaign ||
                campaign[0]?.fields?.hasCampaign == "false"
                    ? false
                    : true;
            campaignTitle.value = campaign[0]?.title;
        }
        // items.value = res?.body?.list.sort((x,y) => x.sortOrder - y.sortOrder);
    }
};

const fetchSubscriptionOffer = async () => {
    let params = {
        textParams: [
            {
                field: "type",
                value: "subscription-offer",
                matchType: "EXACT",
            },
        ],
        nestedParams: [],
        sortParams: [
            {
                field: "title",
                type: "ASCENDING",
            },
        ],
        page: 1,
        size: 100,
    };

    let res = await kvpsClient.search(params);
    if (res.status == 200 && !res.networkError && res?.body?.list) {
        let subscriptionOffer = res?.body?.list.filter((i) => i.type == "subscription-offer");
        
        if (subscriptionOffer.length) {
            subscriptionOfferImg.value = subscriptionOffer[0]?.fields?.image;
            hasSubscriptionOffer.value =
                !subscriptionOffer[0]?.fields?.hasCampaign ||
                subscriptionOffer[0]?.fields?.hasCampaign == "false"
                    ? false
                    : true;
            subscriptionOfferTitle.value = subscriptionOffer[0]?.title;
        }
    }
};

watch(
    () => authStore.currentUser,
    () => {
        // authStore.init();
        if (authStore.isAuthenticated) {
            wishlistStore.initialize();
            show2Modal.value = false;
        }
    },
    { deep: true, immediate: true }
);
onBeforeMount(async() => {
    await fetchCampaign();
    await fetchSubscriptionOffer();
});

onMounted(async () => {
    // notyf.success(`Home`);
    // await fetchCampaign();
    // await fetchSubscriptionOffer();

    authStore.init();
    cartStore.initialize();
    categoryStore.initialize();
    currenciesStore.initialize();
    attributeStore.initialize();
    globalDiscountStore.initialize();
    if (isAuthenticated.value) {
        wishlistStore.initialize();
    }
});
if (process.client) {
    const body = document.querySelector("body");

    watch(
        showModal,
        () => {
            body.style.overflow =
                showModal.value && isHome.value ? "hidden" : "auto";
        },
        { deep: true, immediate: true }
    );

    window.addEventListener("scroll", () => {
        window.onscroll = function (ev) {
            let fixedDiv = document.getElementById("navLink");
            if (fixedDiv != null) {
                if (window.scrollY >= 110)
                    fixedDiv.classList.add("navbar-middle-fixed-section");
                else fixedDiv.classList.remove("navbar-middle-fixed-section");
    
                let fixedTabDiv = document.getElementById("tabNavLink");
                if (window.scrollY >= 108)
                    fixedTabDiv.classList.add("navbar-middle-fixed-section");
                else fixedTabDiv.classList.remove("navbar-middle-fixed-section");
    
                let fixedMobDiv = document.getElementById("mobileNavLink");
                if (window.scrollY >= 108)
                    fixedMobDiv.classList.add("navbar-middle-fixed-section");
                else fixedMobDiv.classList.remove("navbar-middle-fixed-section");
            }
        };
    });
}
</script>

<style scoped>
.initial-popup {
    background-image: url("/images/initial-popup.jpg");
    width: auto;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>

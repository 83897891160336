<template>
    <div class="footer-container">
        <div class="footer-section-upper-container"></div>

        <div class="footer-section-middle-container">
            <div class="footer-search-container subscription-section-mobile my-3 ">
                <p class="footer-line">
                    Join our VIP list for inspiration, new arrivals & more
                </p>

                <div class="search-input-container-2 pb-0">
                    <input
                        class="input-container"
                        v-model="subscriptionEmail"
                        type="text"
                        id="gsearch"
                        name="gsearch"
                        placeholder="ENTER YOUR MAIL"
                        style="padding-left: 10px"
                    />
                    <div class="search-button" @click.prevent="emailSubscription">SUBSCRIBE</div>
                </div>
                <small class="text-danger m-0 p-0">
                    {{ errorMessage.email }}
               </small> 
            </div>
            <div class="footer-parent-section">
                <div class="customer-service-items">
                    <div class="footer-item-name-heading">ABOUT US</div>
                    <div class="footer-item-name">
                        <a href="#" @click.prevent="redirectTo('about')">
                            Our Story
                        </a>
                    </div>
                    <div class="footer-item-name">
                        <a href="#" @click.prevent="redirectTo('outlets')">
                            Find a Store
                        </a>
                    </div>
                    <div class="footer-item-name">
                        <a
                            href="#"
                            @click.prevent="redirectTo('privacy-policy')"
                        >
                            Privacy & Policy
                        </a>
                    </div>
                    <div class="footer-item-name">
                        <a
                            href="#"
                            @click.prevent="redirectTo('terms-conditions')"
                        >
                            Terms & Condition
                        </a>
                    </div>
                </div>
            </div>

            <div class="footer-parent-section">
                <div class="customer-service-items">
                    <div class="footer-item-name-heading">CUSTOMER SERVICE</div>
                    <div class="footer-item-name">
                        <a
                            href="/contact"
                            @click.prevent="redirectTo('contact')"
                            >Contact Us</a
                        >
                    </div>
                    <div class="footer-item-name">
                        <a href="/return" @click.prevent="redirectTo('return')">
                            Return & Refund Policy
                        </a>
                    </div>
                    <div class="footer-item-name">
                        <a href="/shipping" @click.prevent="redirectTo('shipping')">Delivery & Shipping Information</a>
                    </div>
                    <div class="footer-item-name">
                        <a href="/images/DELTA_CATALOG_HOME.pdf" target="_blank">Catalog</a>
                    </div>
                </div>
            </div>

            <div class="footer-search-container subscription-section">
                <p class="footer-line">
                    Join our VIP list for inspiration, new arrivals & more
                </p>

                <div class="search-input-container-2 pb-0">
                    <input
                        class="input-container"
                        v-model="subscriptionEmail"
                        type="text"
                        id="gsearch"
                        name="gsearch"
                        placeholder="ENTER YOUR MAIL"
                        style="padding-left: 10px"
                    />
                    <div class="search-button" @click.prevent="emailSubscription">SUBSCRIBE</div>
                </div>
                <small class="text-danger m-0 p-0">
                    {{ errorMessage.email }}
               </small> 
            </div>
            <div class="footer-search-container">
                <div class="follow-section">
                    <div class="footer-item-name-heading">FOLLOW US</div>
                    <div class="social-icons" style="margin: 12px 0 !important">
                        <a
                            href="https://www.facebook.com/deltafurnishers"
                            target="_blank"
                            class="facebook"
                        >
                            <i class="fa fa-facebook"></i>
                        </a>
                        <a href="#" @click.prevent class="twitter">
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a
                            href="https://www.instagram.com/deltafurnishers/"
                            target="_blank"
                            class="instagram"
                        >
                            <i class="fa fa-instagram fa-lg"></i>
                        </a>
                        <a href="#" @click.prevent class="whatsapp">
                            <i class="fa fa-whatsapp fa-lg"></i>
                        </a>
                        <div></div>
                    </div>
                </div>
                <div class="follow-section">
                    <div class="hotline-section">
                        <div class="call-center common-gap">
                            <div class="headphone">
                                <i class="fa fa-headphones"></i>
                            </div>
                            <div>
                                <a href="tel:16770" class="headphone-span"
                                    >16770</a
                                >,
                            </div>
                            <div>
                                <a
                                    href="tel:+8809611116770"
                                    class="headphone-span"
                                    >+88 09611116770</a
                                >
                            </div>
                        </div>

                        <div class="support-section common-gap">
                            <div class="envelope">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div>
                                <span
                                    class="envelope-section"
                                    @click.prevent="sendMail()"
                                    >crm@akhtargroup.com.bd</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-search-container">
                <div class="follow-section">
                    <div class="footer-item-name-heading">WE ACCEPT</div>
                    <div class="social-icons">
                        <div class="accept" style="
                            display: flex;
                            flex-wrap: wrap;
                            /* margin: 12px 0; */
                            margin-bottom: 5px;
                        ">
                            <img src="/images/footer/AE.svg" class="me-1" style="
                                height: 35px;
                                /* background-color: #fff; */
                            " alt="" />
                            <img src="/images/footer/master.svg" class="mx-1" style="
                                height: 35px;
                                /* background-color: #fff; */
                            " alt="" />
                            <img src="/images/footer/visa.svg" class="mx-1" style="
                                height: 35px;
                                /* background-color: #fff; */
                            " alt="" />
                            <img
                                src="/images/footer/bkash.svg"
                                class="ms-1" style="
                                    height: 35px;
                                    /* background-color: #fff; */
                                "
                                alt=""
                            />
                        </div>
                        <!-- <div
                            class="accept"
                            style="
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 5px;
                            "
                        >
                            <img
                                src="/images/Visa-Logo.svg"
                                style="
                                    height: 35px;
                                    background-color: #fff;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                "
                                alt=""
                            />
                            <img
                                src="/images/MC-Logo.svg"
                                style="
                                    height: 35px;
                                    background-color: #fff;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                "
                                alt=""
                            />
                            <img
                                src="/images/A_E-Logo.svg"
                                style="
                                    height: 35px;
                                    background-color: #fff;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                "
                                alt=""
                            />
                            <img
                                src="/images/BKash-Logo.svg"
                                style="
                                    height: 35px;
                                    background-color: #fff;
                                    margin-bottom: 5px;
                                "
                                alt=""
                            />
                        </div> -->
                    </div>
                    <!-- <div class="" style="font-size: 12px; margin-bottom: 5px">
                        Verified By
                    </div> -->
                    <div class="social-icons ssl-commerz p-0">
                        <div class="accept">
                            <img src="/images/footer/ssl.svg" style="
                                    height: 30px !important;
                                    /* background-color: #fff; */
                                " alt="ssl" />
                        </div>
                    </div>
                    <!-- <div class="social-icons" style="padding: 0 !important">
                        <div class="accept">
                            <img
                                src="/images/4ssl.png"
                                style="height: 25px"
                                alt=""
                            />
                        </div>
                    </div> -->
                    <div
                        class="social-icons"
                        style="padding: 0px !important; font-size: 12px"
                    >
                        TIN : 23356824319000162
                    </div>
                    <div
                        class="social-icons"
                        style="padding: 0px !important; font-size: 12px"
                    >
                        BIN : 002636149-0404
                    </div>
                </div>
            </div>
            <div class="container d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                    <!-- <img src="/images/ssl-full.png" class="w-100" alt="sslcommerz"/> -->
                    <img src="/images/sssl-single.jpeg" class="w-100" alt="sslcommerz"/>
                </div>
            </div>
        </div>


        <div class="footer-section-lower-container">
            <div class="footer-line-4">
                {{ year }} DELTA Furnishers Ltd.
            </div>
        </div>

        <CommonsModalBaseModal 
            title="" 
            :show="mailUsModalVisibility" 
            @close="mailUsModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
            <MailUs @close="mailUsModalVisibility = false" />
        </CommonsModalBaseModal>
    </div>
</template>

<script setup>
import MailUs from "../nav/MailUs.vue";
import { authClient } from "@/assets/apis/config";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
// import { useNotyf } from '@/composables/useNotyf';

// Create an instance of Notyf
const notyf = process.client? new Notyf(): null;

const router = useRouter();

const mailUsModalVisibility = ref(false);
const year = new Date().getFullYear();
const subscriptionEmail = ref("");
const errorMessage = ref({
    email: "",
});
const redirectTo = async (name) => {
    if (name.length > 0) {
        router.push({
            name: name,
        });
    }
};

const sendMail = async () => {
    mailUsModalVisibility.value = !mailUsModalVisibility.value;
};

const call = () => {
    if (process.client) {
        document.getElementById("call").click();
    }
};
const onSuccess = async () => {
    await clearValidations();
    subscriptionEmail.value = "";
};

const clearValidations = async () => {
    Object.keys(errorMessage.value).forEach((key) => {
        errorMessage.value[key] = "";
    });
};

const validate = async () => {
    await clearValidations();
    if (subscriptionEmail.value.trim().length == 0) {
        errorMessage.value.email = "Please Provide Your Email";
        return false;
    }

    if (!subscriptionEmail.value.match(/\S+@\S+\.\S+/)) {
        errorMessage.value.email = "Please Provide a Correct Email";
        return false;
    }
    return true;
};

const emailSubscription = async () => {
    if (await validate()) {
        let data = {
            userId: "",
            email: subscriptionEmail.value,
        };
        authClient.emailSubscription(data).then((res) => {
            if (res.status == 201 && !res.networkError) {
                notyf.success('Subscription Successful');
                // toaster.onSuccess(`Subscription Successful`, {
                //     duration: 1500,
                // });
                onSuccess();
            } else if (res.status == 409 && !res.networkError) {
                notyf.error('Failed!! Already Subscribed with this email');
                errorMessage.value.email = "Already Subscribed with this email";
                // toaster.error(`Failed to Subscribe`, {
                //     duration: 1500,
                // });
            } else {
                notyf.error('Failed to Subscribe');
                // toaster.error(`Failed to Subscribe`, {
                //     duration: 1500,
                // });
            }
        });
    }
    // else {
    //     toaster.error(errorMessage.value.email, {
    //         duration: 1500,
    //     });
    // }
};
</script>

<style lang="scss">
.input-container {
    &::placeholder {
        font-size: 12px;
        font-weight: 450;
        color: rgb(155, 155, 155);
    }
}
.subscription-section {
    // dis
}
.subscription-section-mobile {
    display: none !important;
}
@media screen and (max-width: 700px) {
    .subscription-section {
        display: none !important;
    }
    .subscription-section-mobile {
        display: block !important;
    }
    .input-container {
        border-radius: 0;

        &::placeholder {
            font-size: 14px;
        }
    }
}
</style>

<template>
<div class="navbar-container-mobile" id="mobileNavLink">
    <div class="navbar-top-section">
        <div class="" style="display:flex; width: 100%;">
            <i
                class="fa fa-bars col-2 d-flex justify-content-center align-items-center"
                style="font-size: 24px; cursor: pointer; width: 10%;"
                @click.prevent="showMobileNav()"
            ></i>
            
            <div class="logo-section col-8" style="width: 90%;display:flex;justify-content:center">
                <RouterLink to="/" @click.prevent="gotoIndexPage()">
                    <img
                        class="logo-img" 
                        src="/logo/delta.png" 
                        alt="Logo" 
                    />
                </RouterLink>
            </div>
            
        </div>
    </div>

        <div class="mobile-nav-sidebar" v-show="mobileNavVisibility" id="navLink">
            <div style="padding: 0 25px;">
                <CartSectionMobile @hideNav="showMobileNav"/>
            </div>
            <NavbarItems style="margin-top: 15px;" @hideNav="showMobileNav" type="mobile"/>

        <div class="call-us" >
            <p style="font-size: 16px; margin-bottom: 0px;">
                Call Us
            </p>
        </div>
      
        <div class="mobile-hotline-section">
            <div>
                <a class="headphone" href="tel:16770">
                    <i class="fa fa-headphones"></i>
                    <span class="headphone-span">16770</span>
                </a>
                <div class="envelope">
                    <i class="fa fa-envelope"></i>
                    <span class="envelope-span">crm@akhtargroup.com.bd</span>
                </div>
            </div>
        </div>
    </div>
  
  </div>
  
</template>

<script setup>
import CartSectionMobile from "./CartSectionMobile.vue";
import NavbarItems from "./NavbarItems.vue";

const router = useRouter();

const gotoIndexPage = async () => {
    router.push({
        name: "index",
    })
    mobileNavVisibility.value = false;
}

const mobileNavVisibility = ref(false);

const showMobileNav = async () => {
    mobileNavVisibility.value = !mobileNavVisibility.value;
};

</script>

<style lang="scss">

@media screen and (min-width: 651px) {
  .navbar-container-mobile {
    display: none;
  }
}

.call-us {
    background-color: gainsboro;
    text-align: center;
    padding: 10px 0;
}
.mobile-hotline-section {
    text-decoration: none;
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    &:hover {
        color: none !important;
    }
    .headphone, .envelope {
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: #f84c5a;
        }
        i {
            color: #ea1a21;
            margin-right: 5px;
            
        }
        span {
            &:hover {
                color: #f84c5a;
            }
        }
    }
    .envelope {
        span {
            font-size: 16px;
        }
    }
}

</style>

import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { categoriesClient } from "@/assets/apis/config";
export const useCategoryStore = defineStore("categoryStore", () => {
    const categories = ref([]);
    const allCategories = ref([]);
    const parentCategories = ref([]);
    const isLoading = ref(false);
    const TIMESTAMP = Date.now();

    const rearrange = (arr) => {
        const nest = (xs, id = 0) =>
            xs
                .filter((x) => x && x.parentId == id).sort((x,y) => x.sortOrder - y.sortOrder)
                .map((x) => ({ ...x, children: nest(xs, x.id) }));
        return nest(arr);
    };

    const fetchAllCategory = async () => {
        let totalCategories = ref(-1);
        const req = {
            page: 1,
            size: 50,
            fetchCount: true,
            textParams: [],
        };

        let nextPageToken = "";

        let cateArr = [];
        do {
            let res = await categoriesClient.search(req);
            if ((res.status = 200 && !res.networkError)) {
                if (res.body && res.body.list && res.body.list.length > 0) {
                    let resArr = [];
                    res.body.list.forEach((element) => {
                        if (element && element.id.length > 0) {
                            resArr.push(element);
                        }
                    });
                    let list = resArr.sort((x, y) => x.sortOrder - y.sortOrder);

                    cateArr = cateArr.concat(list);

                    nextPageToken = res.body.nextPageToken;
                    if (res.body.total > -1) {
                        totalCategories.value = res.body.total;
                        req.fetchCount = false;
                    }
                    req.page++;
                }
                isLoading.value = false;
            }
        } while (nextPageToken != "");
        let listVal = await rearrange(cateArr);
        categories.value = listVal;
        allCategories.value = cateArr;
        parentCategories.value = cateArr.filter(i => !i.parentId.length);
        
        if (process.client && categories.value > 0) {
            localStorage.setItem(
                "all-categories",
                JSON.stringify({
                    categories: categories.value,
                    initial: TIMESTAMP,
                    expiresOn: TIMESTAMP + 1000 * 60 * 60 * 24 * 1, // 1day
                })
            );
        }
    };

    // /* rearranging the categories list */
    // const newCategory = computed(() => {
    //     let newArr = [];
    //     const nest = (xs, id = 0) => xs.filter (x => x && x.parentId == id ).map ((x) => ({...x, children: nest(xs, x.id)}))
    //     newArr = nest(categories.value)
    //     console.log(newArr);
    //     return newArr;
    // })

    function initialize() {
        // isLoading.value = true;
        // if (localStorage.getItem("all-categories")) {
        //     let categoriesVal = JSON.parse(
        //         localStorage.getItem("all-categories")
        //     );
        //     let expireDate = categoriesVal.expiresOn;

        //     if (Date.now() > expireDate) {
        //         localStorage.removeItem("all-categories");
        //     }
        //     fetchAllCategory();
        // } else {
            fetchAllCategory();
        // }
    }

    return { categories, allCategories, parentCategories, isLoading, initialize };
});

import { default as aboutEuAvn3XNdLMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/about.vue?macro=true";
import { default as cartU80yDPoiYnMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/cart.vue?macro=true";
import { default as cartV1EObxSLtNsDMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/cartV1.vue?macro=true";
import { default as checkoutv4zPcuP4qRMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkout.vue?macro=true";
import { default as checkoutV1DpmG8KRmkUMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV1.vue?macro=true";
import { default as checkoutV2wogJtfwfFKMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV2.vue?macro=true";
import { default as checkoutV3BJG4twcrmCMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV3.vue?macro=true";
import { default as coming_45soonwlUdHmV4TsMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/coming-soon.vue?macro=true";
import { default as contactCzAS9K7bG5Meta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/contact.vue?macro=true";
import { default as detailscq8tF76nTEMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/details.vue?macro=true";
import { default as detailsV1LgpM90hfqTMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/detailsV1.vue?macro=true";
import { default as indexrjC7ihGVI9Meta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/index.vue?macro=true";
import { default as new_45arrivalojfdJuq0WIMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/new-arrival.vue?macro=true";
import { default as orderslSTPOnOxDpMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/orders.vue?macro=true";
import { default as outletsZ2P62T29rPMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/outlets.vue?macro=true";
import { default as cancelMJzMj2myeoMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/cancel.vue?macro=true";
import { default as failed8PLvH4idRtMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/failed.vue?macro=true";
import { default as later7ksIIjYQJqMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/later.vue?macro=true";
import { default as successCIuvamc6szMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/success.vue?macro=true";
import { default as privacy_45policyWjcSKsXxoAMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/privacy-policy.vue?macro=true";
import { default as profileYa3nEr3AP9Meta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/profile.vue?macro=true";
import { default as returnXjmA782BdxMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/return.vue?macro=true";
import { default as sales95bBCDN1awMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sales.vue?macro=true";
import { default as shippingVjqug860FBMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shipping.vue?macro=true";
import { default as shop1ei07fWAoCMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shop.vue?macro=true";
import { default as shopV1BBcx2B7E0kMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shopV1.vue?macro=true";
import { default as sign_45innHu4HJVPhFMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sign-in.vue?macro=true";
import { default as sign_45upFV4ldKISwsMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sign-up.vue?macro=true";
import { default as terms_45conditionsiEGfwaCFTrMeta } from "/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: aboutEuAvn3XNdLMeta?.name ?? "about",
    path: aboutEuAvn3XNdLMeta?.path ?? "/about",
    meta: aboutEuAvn3XNdLMeta || {},
    alias: aboutEuAvn3XNdLMeta?.alias || [],
    redirect: aboutEuAvn3XNdLMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/about.vue").then(m => m.default || m)
  },
  {
    name: cartU80yDPoiYnMeta?.name ?? "cart",
    path: cartU80yDPoiYnMeta?.path ?? "/cart",
    meta: cartU80yDPoiYnMeta || {},
    alias: cartU80yDPoiYnMeta?.alias || [],
    redirect: cartU80yDPoiYnMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartV1EObxSLtNsDMeta?.name ?? "cartV1",
    path: cartV1EObxSLtNsDMeta?.path ?? "/cartV1",
    meta: cartV1EObxSLtNsDMeta || {},
    alias: cartV1EObxSLtNsDMeta?.alias || [],
    redirect: cartV1EObxSLtNsDMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/cartV1.vue").then(m => m.default || m)
  },
  {
    name: checkoutv4zPcuP4qRMeta?.name ?? "checkout",
    path: checkoutv4zPcuP4qRMeta?.path ?? "/checkout",
    meta: checkoutv4zPcuP4qRMeta || {},
    alias: checkoutv4zPcuP4qRMeta?.alias || [],
    redirect: checkoutv4zPcuP4qRMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutV1DpmG8KRmkUMeta?.name ?? "checkoutV1",
    path: checkoutV1DpmG8KRmkUMeta?.path ?? "/checkoutV1",
    meta: checkoutV1DpmG8KRmkUMeta || {},
    alias: checkoutV1DpmG8KRmkUMeta?.alias || [],
    redirect: checkoutV1DpmG8KRmkUMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV1.vue").then(m => m.default || m)
  },
  {
    name: checkoutV2wogJtfwfFKMeta?.name ?? "checkoutV2",
    path: checkoutV2wogJtfwfFKMeta?.path ?? "/checkoutV2",
    meta: checkoutV2wogJtfwfFKMeta || {},
    alias: checkoutV2wogJtfwfFKMeta?.alias || [],
    redirect: checkoutV2wogJtfwfFKMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV2.vue").then(m => m.default || m)
  },
  {
    name: checkoutV3BJG4twcrmCMeta?.name ?? "checkoutV3",
    path: checkoutV3BJG4twcrmCMeta?.path ?? "/checkoutV3",
    meta: checkoutV3BJG4twcrmCMeta || {},
    alias: checkoutV3BJG4twcrmCMeta?.alias || [],
    redirect: checkoutV3BJG4twcrmCMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/checkoutV3.vue").then(m => m.default || m)
  },
  {
    name: coming_45soonwlUdHmV4TsMeta?.name ?? "coming-soon",
    path: coming_45soonwlUdHmV4TsMeta?.path ?? "/coming-soon",
    meta: coming_45soonwlUdHmV4TsMeta || {},
    alias: coming_45soonwlUdHmV4TsMeta?.alias || [],
    redirect: coming_45soonwlUdHmV4TsMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: contactCzAS9K7bG5Meta?.name ?? "contact",
    path: contactCzAS9K7bG5Meta?.path ?? "/contact",
    meta: contactCzAS9K7bG5Meta || {},
    alias: contactCzAS9K7bG5Meta?.alias || [],
    redirect: contactCzAS9K7bG5Meta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: detailscq8tF76nTEMeta?.name ?? "details",
    path: detailscq8tF76nTEMeta?.path ?? "/details",
    meta: detailscq8tF76nTEMeta || {},
    alias: detailscq8tF76nTEMeta?.alias || [],
    redirect: detailscq8tF76nTEMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/details.vue").then(m => m.default || m)
  },
  {
    name: detailsV1LgpM90hfqTMeta?.name ?? "detailsV1",
    path: detailsV1LgpM90hfqTMeta?.path ?? "/detailsV1",
    meta: detailsV1LgpM90hfqTMeta || {},
    alias: detailsV1LgpM90hfqTMeta?.alias || [],
    redirect: detailsV1LgpM90hfqTMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/detailsV1.vue").then(m => m.default || m)
  },
  {
    name: indexrjC7ihGVI9Meta?.name ?? "index",
    path: indexrjC7ihGVI9Meta?.path ?? "/",
    meta: indexrjC7ihGVI9Meta || {},
    alias: indexrjC7ihGVI9Meta?.alias || [],
    redirect: indexrjC7ihGVI9Meta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: new_45arrivalojfdJuq0WIMeta?.name ?? "new-arrival",
    path: new_45arrivalojfdJuq0WIMeta?.path ?? "/new-arrival",
    meta: new_45arrivalojfdJuq0WIMeta || {},
    alias: new_45arrivalojfdJuq0WIMeta?.alias || [],
    redirect: new_45arrivalojfdJuq0WIMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/new-arrival.vue").then(m => m.default || m)
  },
  {
    name: orderslSTPOnOxDpMeta?.name ?? "orders",
    path: orderslSTPOnOxDpMeta?.path ?? "/orders",
    meta: orderslSTPOnOxDpMeta || {},
    alias: orderslSTPOnOxDpMeta?.alias || [],
    redirect: orderslSTPOnOxDpMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: outletsZ2P62T29rPMeta?.name ?? "outlets",
    path: outletsZ2P62T29rPMeta?.path ?? "/outlets",
    meta: outletsZ2P62T29rPMeta || {},
    alias: outletsZ2P62T29rPMeta?.alias || [],
    redirect: outletsZ2P62T29rPMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/outlets.vue").then(m => m.default || m)
  },
  {
    name: cancelMJzMj2myeoMeta?.name ?? "payments-response-cancel",
    path: cancelMJzMj2myeoMeta?.path ?? "/payments/response/cancel",
    meta: cancelMJzMj2myeoMeta || {},
    alias: cancelMJzMj2myeoMeta?.alias || [],
    redirect: cancelMJzMj2myeoMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/cancel.vue").then(m => m.default || m)
  },
  {
    name: failed8PLvH4idRtMeta?.name ?? "payments-response-failed",
    path: failed8PLvH4idRtMeta?.path ?? "/payments/response/failed",
    meta: failed8PLvH4idRtMeta || {},
    alias: failed8PLvH4idRtMeta?.alias || [],
    redirect: failed8PLvH4idRtMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/failed.vue").then(m => m.default || m)
  },
  {
    name: later7ksIIjYQJqMeta?.name ?? "payments-response-later",
    path: later7ksIIjYQJqMeta?.path ?? "/payments/response/later",
    meta: later7ksIIjYQJqMeta || {},
    alias: later7ksIIjYQJqMeta?.alias || [],
    redirect: later7ksIIjYQJqMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/later.vue").then(m => m.default || m)
  },
  {
    name: successCIuvamc6szMeta?.name ?? "payments-response-success",
    path: successCIuvamc6szMeta?.path ?? "/payments/response/success",
    meta: successCIuvamc6szMeta || {},
    alias: successCIuvamc6szMeta?.alias || [],
    redirect: successCIuvamc6szMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/payments/response/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyWjcSKsXxoAMeta?.name ?? "privacy-policy",
    path: privacy_45policyWjcSKsXxoAMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyWjcSKsXxoAMeta || {},
    alias: privacy_45policyWjcSKsXxoAMeta?.alias || [],
    redirect: privacy_45policyWjcSKsXxoAMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileYa3nEr3AP9Meta?.name ?? "profile",
    path: profileYa3nEr3AP9Meta?.path ?? "/profile",
    meta: profileYa3nEr3AP9Meta || {},
    alias: profileYa3nEr3AP9Meta?.alias || [],
    redirect: profileYa3nEr3AP9Meta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: returnXjmA782BdxMeta?.name ?? "return",
    path: returnXjmA782BdxMeta?.path ?? "/return",
    meta: returnXjmA782BdxMeta || {},
    alias: returnXjmA782BdxMeta?.alias || [],
    redirect: returnXjmA782BdxMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/return.vue").then(m => m.default || m)
  },
  {
    name: sales95bBCDN1awMeta?.name ?? "sales",
    path: sales95bBCDN1awMeta?.path ?? "/sales",
    meta: sales95bBCDN1awMeta || {},
    alias: sales95bBCDN1awMeta?.alias || [],
    redirect: sales95bBCDN1awMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: shippingVjqug860FBMeta?.name ?? "shipping",
    path: shippingVjqug860FBMeta?.path ?? "/shipping",
    meta: shippingVjqug860FBMeta || {},
    alias: shippingVjqug860FBMeta?.alias || [],
    redirect: shippingVjqug860FBMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: shop1ei07fWAoCMeta?.name ?? "shop",
    path: shop1ei07fWAoCMeta?.path ?? "/shop",
    meta: shop1ei07fWAoCMeta || {},
    alias: shop1ei07fWAoCMeta?.alias || [],
    redirect: shop1ei07fWAoCMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: shopV1BBcx2B7E0kMeta?.name ?? "shopV1",
    path: shopV1BBcx2B7E0kMeta?.path ?? "/shopV1",
    meta: shopV1BBcx2B7E0kMeta || {},
    alias: shopV1BBcx2B7E0kMeta?.alias || [],
    redirect: shopV1BBcx2B7E0kMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/shopV1.vue").then(m => m.default || m)
  },
  {
    name: sign_45innHu4HJVPhFMeta?.name ?? "sign-in",
    path: sign_45innHu4HJVPhFMeta?.path ?? "/sign-in",
    meta: sign_45innHu4HJVPhFMeta || {},
    alias: sign_45innHu4HJVPhFMeta?.alias || [],
    redirect: sign_45innHu4HJVPhFMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upFV4ldKISwsMeta?.name ?? "sign-up",
    path: sign_45upFV4ldKISwsMeta?.path ?? "/sign-up",
    meta: sign_45upFV4ldKISwsMeta || {},
    alias: sign_45upFV4ldKISwsMeta?.alias || [],
    redirect: sign_45upFV4ldKISwsMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsiEGfwaCFTrMeta?.name ?? "terms-conditions",
    path: terms_45conditionsiEGfwaCFTrMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsiEGfwaCFTrMeta || {},
    alias: terms_45conditionsiEGfwaCFTrMeta?.alias || [],
    redirect: terms_45conditionsiEGfwaCFTrMeta?.redirect || undefined,
    component: () => import("/home/shipu/workspace/Vue/delta/delta-nuxt-pub/pages/terms-conditions.vue").then(m => m.default || m)
  }
]
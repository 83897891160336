<template>
    
  <div v-click-outside="onOutsideClick" v-if="cartStore.items.length > 0" >
    <div class="body-section">
      <div class="cart-header-container row">
        <div class="col-lg-8">
          <div class="title">Your Cart</div>
        </div>
      </div>
      
      <CartDropdownItem
        v-for="(item, i) in cartStore.items"
        :key="i"
        :item="item"
      />
    </div>

    <div>
      <hr style="width: 100%" />
    </div>

    <div class="cart-total-section" > 
      <div class="row" >
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <strong>Total: </strong>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5" style="display: flex; justify-content: flex-end">
          <strong>{{ itemCurrency }} {{ total }}</strong>
        </div>
      </div>
    </div>

    <div class="view-more-btn">
      <div class="clear-all-btn" @click.prevent="cartStore.removeAll()">
        <i class="fa fa-trash"></i> Clear All
      </div>

      <div class="view-btn" @click.prevent="gotoCartDetailPage">
        <i class="fa fa-shopping-cart"></i> View Cart
      </div>
    </div>

    <div class="clear-all-btn-area"></div>
  </div>

  <div class="body-section" v-click-outside="onOutsideClick" v-else>
    <div class="empty-image-section">
      <img class="empty-img" src="/images/cart.gif" alt="" />
      <p
        style="
          font-size: 16px;
          color: #f84c5a;
          text-align: center;
          font-weight: 600;
        "
      >
        YOUR CART IS EMPTY
      </p>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from "@/stores/cart";
import CartDropdownItem from "./CartDropdownItem.vue";
import { useCurrenciesStore } from "@/stores/currencies-store";

const router = useRouter();
const currenciesStore = useCurrenciesStore();
const cartStore = useCartStore();

const props = defineProps({
    cartStore:{
        type: Object,
        required: true
    }
})

const emit = defineEmits(["onDropdownModalVisibility", "hide-nav"]);
const gotoCartDetailPage = () => {
  emit("onDropdownModalVisibility", false);

  router.push({
    name: "cart",
  });

  emit('hide-nav')
};

const onOutsideClick = () => {
    emit("onDropdownModalVisibility", false);
    emit('hide-nav')
};

const total = computed(() => {
    let total = 0;
    if (currenciesStore.country && currenciesStore.country.currency != "BDT" && currenciesStore.country.rate > 0) {
        if (cartStore.total > 0) {
            total = (Number(cartStore.total) / Number(currenciesStore.country.rate)).toFixed(2);
        }
    } else {
        if (cartStore.total > 0) {
            total = (Number(cartStore.total)).toFixed(2);
        }
    }

    return total;
});

const itemCurrency = computed(() => {
    let currency = "BDT"
    if (currenciesStore.country && currenciesStore.country.currency && currenciesStore.country.currency.length > 0) {
        currency = currenciesStore.country.currency;
    } 

    return currency;
});
</script>

<style lang="scss">
.cart-dropdown {
  position: absolute;
  top: 75%;
  right: 0;
  border: 1px solid #fff;
  border-radius: 10px;

  width: 500px;

  background-color: #ffffff;
  box-shadow: 0px 2px 21px 0px #e6e6e6;
  padding: 20px 10px;

  height: auto;
  z-index: 9999;

  &.cart-dropdown-active {
    right: 0px;
    transition: right 0.3s ease-in;
    z-index: 99999;
  }

  .empty-image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    .empty-img {
      height: auto;
      width: 200px;
    }
    p {
        color: #ea1a21;
    }
  }

  .body-section {
    max-height: 300px;
    min-height: 300px;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: red #fff;
    scrollbar-width: 2px;
  }

  .cart-header-container {
    text-align: left;
    margin-bottom: 20px;
    font-weight: 700;
    .title {
        font-weight: 600;
    }
  }

  .cart-item-container {
    // display: flex;
    align-items: flex-start;
    // margin: 0px 20px 15px 0px;
    margin: 0;
    .image-container {
      display: flex;
      // justify-content: flex-end;
      .image {
        border: 1px solid #fff;
        // border-radius: 10%;

        width: 80px;
        height: 80px;
        // background-image: url("/src/assets/img/no-image.jpeg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .text {
      font-size: 16px;
    }
    .fa-trash {
      cursor: pointer;
    }
    .fa-trash:hover {
      color: red;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 450px) {
    .cart-item-container {
      // display: flex;
      align-items: flex-start;
      margin: 0px 0px 15px 0px;
      .image-container {
        display: flex;
        // justify-content: flex-end;
        .image {
          border: 1px solid red;
          border-radius: 10%;

          width: 60px;
          height: 60px;
          background-image: url("../../../assets/img/no-image.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .text {
        font-size: 14px;
      }
      .fa-trash {
        cursor: pointer;
      }
      .fa-trash:hover {
        color: red;
      }
    }
  }

  @media screen and (min-width: 0px) and (max-width: 500px) {
    .body-section {
        .cart-item-container {
            ul {
                li {
                    font-size: 10px !important;
                    strong {
                        font-size: 10px !important;
                    }
                }
            }
            .text {
                strong {
                    font-size: 10px !important;
                }
            }
        }
    }
    .cart-total-section {
      // background-color: red;
      font-size: 10px;
    }
  }

  .view-more-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .clear-all-btn {
      // text-align: center;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: #e6e6e6;

      font-weight: 700;
      color: #000;
      font-size: 16px;

      padding: 10px;

      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: rgb(91, 89, 89);
      }
    }

    .view-btn {
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: #000;

      font-weight: 700;
      color: #fff;
      font-size: 16px;

      padding: 10px;

      cursor: pointer;
      &:hover {
        background-color: rgb(113, 97, 97);
      }
    }
  }
  // .clear-all-btn-area {
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 10px;
  // }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
  .cart-dropdown {
    width: 100%;
  }
}
@media screen and (min-width: 577px) and (max-width: 767px) {
//   .cart-dropdown {
//     width: 75%;
//   }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
//   .cart-dropdown {
//     width: 55%;
//   }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
//   .cart-dropdown {
//     width: 45%;
//   }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
//   .cart-dropdown {
//     width: 100%;
//   }
}
</style>

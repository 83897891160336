<template>
   <div class="flag-section" style="position: relative; display: flex;">
      <div class="lower-slide-section right-lower-slide-section" @click.prevent="showList()">
        <img
          class="bd-flag"
          style="width: 25px; height: 15px"
          :src="currentFlag.img"
          alt="flag"
        />
        <span style="font-size: 12px">{{ currentFlag.currency }}</span>
        <img
          class="drop-down"
          style="width: 12px; height: 12px"
          src="../../../../assets/images/one/dropdown.png"
          alt="flag"
        />
      </div>
      <ul
        class="flag-list drop-down-menu"
        v-if="currencyList"
        v-click-outside="onOutsideClick"
        :class="[currencyList == true && 'list-click']"
      >
        <li
          class="option"
          v-for="(item, index) in currenciesStore.currencies"
          :key="index"
          @click.prevent="selectFlag(item)"
        >
          <div class="lower-slide-section">
            <img
              class="bd-flag"
              style="width: 25px"
              :src="item.img"
              alt="flag"
            />
            <span>{{ item.currency }}</span>
          </div>
        </li>
      </ul>
    </div>
</template>

<script setup>
import { useCurrenciesStore } from "@/stores/currencies-store";
const currenciesStore = useCurrenciesStore();
const currencyList = ref(false);

const showList = () => {
  currencyList.value = !currencyList.value;
};

const flagsArray = [
  {
    img: "/images/one/flags/bdflag.png",
    currency: "BDT",
    country: 'bangladesh',
    numCode: '+88',
  },
  {
    img: "/images/one/flags/usflag.png",
    currency: "USD",
    country: 'usa',
    numCode: '+1'
  },
  {
    img: "/images/one/flags/indiaflag.png",
    currency: "INR",
    country: 'india',
    numCode: '+91'
  },
];

const currentFlag = ref(flagsArray[0]);

const selectFlag = (item) => {
    currentFlag.value = item;
    currencyList.value = !currencyList.value;
    currenciesStore.setCountry(item);
};

const onOutsideClick = () => {
  currencyList.value = false;
};
</script>

<style lang="scss">
.search-input {
    visibility: hidden;
}
.search-input-container {
    border: 1px solid #f6f6f6 !important;
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    transition: all .3s ease-out 0s !important;
    padding: 12px !important;
    &::placeholder {
        font-size: 16px;
    }
}
.search-img {
    right: 30px !important;
    font-size: 20px !important;
}
.search-close-img {
    right: 35px !important;
    font-size: 20px !important;
}

.flag-section {
  min-width: 70px;
//   margin: 0 3% !important;
  margin: 0 15px !important;
  cursor: pointer;
}
</style>

<template>
    <div class="right-section" style="display: flex">
        <div class="right-upper-side">
            <div class="upper-side-section" @click.prevent="goToOutletPage()">
                <img
                    class="location-logo"
                    src="../../../../assets/images/one/locaiton2.png"
                    alt="location"
                />
                <p>Showrooms</p>
            </div>

            <div
                class="upper-side-section profile-section"
                @click.prevent="showAccount"
            >
                <img
                    class="account-logo"
                    src="../../../../assets/images/one/account.jpeg"
                    alt="account"
                />
                <p>
                    {{ myAccount.charAt(0).toUpperCase() + myAccount.slice(1) }}
                </p>
            </div>

            <ul
                class="account-list drop-down-menu"
                v-if="accountList"
                v-click-outside="onOutsideClick"
                :class="[accountList == true && 'list-click']"
            >
                <li
                    class="option"
                    style="font-size: 20px"
                    v-for="(item, index) in accountArray"
                    :key="index"
                    @click.prevent="onAccountClick(item)"
                >
                    <a
                        class="lower-slide-section"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>
                            <i class="fa" :class="item.img"></i>
                            <span>{{ item.title }}</span>
                        </div>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>

            <div
                class="upper-side-section cart-section desktop"
                @click.prevent="showCart()"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-cart"
                    >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path
                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                        ></path>
                    </svg>
                </div>
                <span class="cart-span" v-if="cartStore.quantity > 0">{{
                    cartStore.quantity
                }}</span>
            </div>
            <div
                class="upper-side-section cart-section desktop"
                @click.prevent="showFavorite()"
            >
                <i class="fa fa-heart-o" style="font-size: 22px"></i>
                <span v-if="isAuthenticated && wishlistStore.totalCount > 0">{{
                    wishlistStore.totalCount
                }}</span>
            </div>
        </div>

        <CartDropdown
            v-if="cartVisibility"
            :class="{ 'cart-dropdown-active': isActive }"
            class="cart-dropdown"
            @onDropdownModalVisibility="cartVisibility = false"
            :cartStore="cartStore"
            @hideNav="$emit('hideNav')"
        />

        <CommonsModalBaseModal 
            title="" 
            :show="signInModalVisibility" 
            @close="signInModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
        <SignIn
                v-if="modalTab == 'signIn'"
                @close="signInModalVisibility = false"
                @openSignUp="openSignUp"
            />
        </CommonsModalBaseModal>
        <CommonsModalBaseModal 
            title="" 
            :show="signUpModalVisibility" 
            @close="signUpModalVisibility = false" 
            maxWidth="70%" 
            :modalFooter="false"
        >
            <SignUp
                @openSignIn="openSignIn"
                @close="signUpModalVisibility = false"
            />
        </CommonsModalBaseModal>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import CartDropdown from "../../cart/CartDropdown.vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth-store";
import SignIn from "@/components/commons/SignIn.vue";
import SignUp from "@/components/commons/SignUp.vue";
import { useWishlistStore } from "@/stores/wishlist-store";

const router = useRouter();
const cartStore = useCartStore();
const authStore = useAuthStore();
const wishlistStore = useWishlistStore();

const cartVisibility = ref(false);
const currencyList = ref(false);
const accountList = ref(false);
const signInModalVisibility = ref(false);
const signUpModalVisibility = ref(false);
const isActive = ref(false);
const emit = defineEmits(["hide-nav"]);
const modalTab = "signIn";

const accountArray = [
    {
        title: "Profile",
        img: "fa-user",
        path: "profile",
    },
    {
        title: "Sign Out",
        img: "fa-power-off",
    },
];

const showCart = async () => {
    cartVisibility.value = !cartVisibility.value;
    isActive.value = true;
};
const openSignUp = () => {
    signUpModalVisibility.value = true;
    signInModalVisibility.value = false;
};
const openSignIn = () => {
    signUpModalVisibility.value = false;
    signInModalVisibility.value = true;
};

const goToOutletPage = () => {
    router.push({
        name: "outlets",
    });
};
const onAccountClick = (item) => {
    if (item.path && item.path == "profile") {
        router.push({
            name: "profile",
        });
    } else {
        onSignOut();
    }
    accountList.value = false;
};

const showAccount = () => {
    if (authStore.currentUser != null) {
        accountList.value = !accountList.value;
    } else {
        signInModalVisibility.value = true;
    }
};

const onSignOut = async () => {
    await authStore.signOut();
    router.push({
        name: "index",
    });
};
const myAccount = computed(() => {
    let title = "";
    if (authStore.currentUser != null) {
        title = authStore.profile.name.toLowerCase();
    } else {
        title = "My Account";
    }
    return title;
});

const isAuthenticated = computed(() => {
    let ac = false;
    if (authStore.currentUser != null) {
        ac = true;
    }
    return ac;
});

const showFavorite = () => {
    if (isAuthenticated.value) {
        router.push({
            name: "profile",
            query: {
                tab: "wishlist",
            },
        });
    } else signInModalVisibility.value = true;
};

const onOutsideClick = () => {
    currencyList.value = false;
    accountList.value = false;
};
</script>

<style lang="scss">
.flag-list {
    display: none;
    max-height: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    opacity: 0;
    overflow: inherit;
    padding: 0;
    position: absolute;
    top: 45px;
    left: -15px;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
}
.flag-list:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.flag-list:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2b1a41;
    z-index: 0;
}
.list-click {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
    max-height: 300px;
    display: block !important;
}
.account-list {
    display: none;
    max-height: 300px;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    overflow-y: inherit;
    padding: 15px;
    position: absolute;
    top: 45px;
    right: 0%;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    z-index: 3026;
    background: #fff;
    border: 1px solid #cacaca;
}
.drop-down-menu:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 40%;
    z-index: 1;
}

.drop-down-menu:after {
    content: "";
    position: absolute;
    right: 40%;
    top: -21px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-bottom-color: #2b1a41;
    z-index: 0;
}
.account-list .option:hover {
    color: #ea1a21;
}
.account-list .option .lower-slide-section span {
    margin-left: 10px;
}
.option {
    opacity: 1 !important;
    display: list-item !important;
    cursor: pointer;
    font-weight: 400;
    line-height: 40px !important;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 10px;
    padding-right: 40px;
    padding: 10px 20px;
    text-align: left;
    transition: all 0.2s;
    margin-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    div {
        .bd-flag {
            margin-right: 5px;
        }
    }
}
.option:last-child {
    border-bottom: none;
}
</style>
